import * as React from "react";
import { Suspense, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import {
  Badge,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import NavBarUrl from "./NavBarUrl";

const AccountDialog = React.lazy(() =>
  import("./Dialogs/Account/AccountDialog")
);
const AdminButton = React.lazy(() => import("./AdminButton"));
const MultiAccountClientButton = React.lazy(() =>
  import("./MultiAccountClientButton")
);

function stringAvatar(name) {
  if (!name) {
    return;
  }
  if (name.split(" ").length === 1) {
    return {
      children: `${name[0]}`,
    };
  } else {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
}

const NavBarAvatarActionContainer = ({ user, config, c }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);

  let open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/logout");
  };

  let showExtraAction = () => {
    if (config?.ADMIN) {
      return (
        <Suspense
          fallback={
            <MenuItem disabled>
              <Skeleton width={"100%"} height={25} />
            </MenuItem>
          }
        >
          <AdminButton />
        </Suspense>
      );
    }
    if (
      c &&
      config?.USER_ACCOUNTS &&
      Object.keys(config.USER_ACCOUNTS).length > 1
    ) {
      return (
        <Suspense
          fallback={
            <MenuItem disabled>
              {" "}
              <Skeleton width={"100%"} height={25} />
            </MenuItem>
          }
        >
          <MultiAccountClientButton
            label={config.USER_ACCOUNTS[config.USER_ACCOUNT_ID]}
            onClick={() => setAccountDialogOpen(true)}
          />
        </Suspense>
      );
    }
  };

  let renderAccountDialog = () => {
    if (
      c &&
      config?.USER_ACCOUNTS &&
      Object.keys(config.USER_ACCOUNTS).length > 1
    ) {
      return (
        <Suspense>
          <AccountDialog
            open={accountDialogOpen}
            setOpen={setAccountDialogOpen}
          />
        </Suspense>
      );
    }
  };

  let showLegacyUrl = () => {
    if (
      c === false &&
      !location.pathname.startsWith("/admin") &&
      !location.pathname.startsWith("/confidentiality-management")
    ) {
      return (
        <Suspense>
          <NavBarUrl
            dataTestId="legacyUrl"
            url={config.LEGACY_URL}
            urlText="Go to Legacy MXdoc"
            mobileUrlText="Legacy MXdoc"
          />
        </Suspense>
      );
    }
  };

  let showConfidentialityManagement = () => {
    if (
      c === false &&
      !location.pathname.startsWith("/admin") &&
      !location.pathname.startsWith("/confidentiality-management")
    ) {
      let hasAccessToConfidentialityManagement = config.CONFIDENTIALITY_MANAGEMENT;
      let title = "";
      let serviceNowTicketUrl = "";
      if (!hasAccessToConfidentialityManagement) {
        title = config.CONFIDENTIALITY_MANAGEMENT_ACCESS_DENIED_MESSAGE;
        serviceNowTicketUrl =
          config.CONFIDENTIALITY_MANAGEMENT_SERVICE_NOW_TICKET;
      }
      return (
        <Suspense>
          <NavBarUrl
            url={
              hasAccessToConfidentialityManagement
                ? "/confidentiality-management"
                : serviceNowTicketUrl
            }
            urlText="Confidentiality Management"
            mobileUrlText="Confidentiality"
            title={title}
          />
        </Suspense>
      );
    }
  };

  return (
    <div id="action-container" data-testid="navBarActionContainer">
      {showLegacyUrl()}
      {renderAccountDialog()}
      {showConfidentialityManagement()}
      <div data-testid="navBarActionContainerDropdown">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <ExpandCircleDownIcon
                sx={{
                  fontSize: 20,
                  color: "white",
                  transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s ease-in-out",
                }}
              />
            }
          >
            <Avatar
              {...stringAvatar(user.profile.name)}
              sx={{
                height: "2.5rem",
                width: "2.5rem",
                maxHeight: "70px",
                maxWidth: "70px",
                backgroundColor: "#b41467",
              }}
            />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock={!accountDialogOpen}
          style={{ zIndex: 2000 }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              width: "12.5rem",

              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                backgroundColor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Stack
            sx={{ px: 2, py: 1 }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Typography variant="h6">{user.profile.name}</Typography>
            <Typography variant="subtitle2">{user.profile.email}</Typography>
          </Stack>
          <Divider sx={{ backgroundColor: "#797979" }} />
          <MenuItem onClick={handleLogout} sx={{ mt: 1 }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
          {showExtraAction()}
        </Menu>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user,
    config: state.config,
    c: state.c,
  };
};
export default connect(mapStateToProps)(NavBarAvatarActionContainer);
